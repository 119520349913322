import React, { useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import ProjectImg from '../Image/ProjectImg';
import Title from '../Title/Title';

const Tech = () => {
  const { tech, projects, titles } = useContext(PortfolioContext);
  const { techTitle, projectsTitle } = titles;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  return (
    <section id="techstack">
      <div className="project-wrapper">
        <Title title={techTitle || ''} />
        <Row>
          {tech.map((project) => {
            const { title, img, id } = project;
            return (
              <Col xs={4} md={3} key={id}>
                <Fade
                  right={isDesktop}
                  bottom={isMobile}
                  duration={1000}
                  delay={1000}
                  distance="30px"
                >
                  <OverlayTrigger overlay={<Tooltip id={`tooltip-${id}`}>{title}</Tooltip>}>
                    <Row className="project-wrapper__card">
                      <Col md={2} sm={2}>
                        <div data-tilt className="thumbnail rounded">
                          <ProjectImg alt={title} filename={img} />
                        </div>
                      </Col>
                      <Col md={10} sm={10}>
                        <div className="project-wrapper__image-description">{title}</div>
                      </Col>
                    </Row>
                  </OverlayTrigger>
                </Fade>
              </Col>
            );
          })}
        </Row>
        <Title title={projectsTitle || ''} />
        {projects.map((project) => {
          const { title, img, url, description } = project;
          return (
            <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
              <div className="card-container" style={{ margin: '10px' }}>
                <a href={url}>
                  <div className="card card-1">
                    <ProjectImg alt={title} filename={img} />
                    <h2 className="card__title">{title}</h2>
                    <h3 className="card__description">{description}</h3>
                  </div>
                </a>
              </div>
            </Fade>
          );
        })}
      </div>
    </section>
  );
};

export default Tech;
